import type { Api } from '@rialtic/api'
import type { PaAPI } from '@rialtic/types'
import { format, min } from 'date-fns'
import { acceptHMRUpdate, defineStore } from 'pinia'
import {
  ALL_LOB,
  datePresets,
  dateRangePatternAlt,
  sinceInceptionDate,
} from '~/constants'
import { useAppConfiguration } from './appConfiguration'
import { useConnectorAnalytics } from './connectorAnalytics'

const DATE_START_KEY = 'start_date'
const DATE_END_KEY = 'end_date'

interface ConnectorOptions {
  force?: boolean
  middleware?: boolean
}
interface MuxFilters {
  connector_id: string
  start_date: string
  end_date: string
}

interface User {
  id: string
  email: string
  first_name: string
  last_name: string
}

type LocalConnector = PaAPI.Get['/connector/{id}'] & {
  isManager?: boolean
}

export enum LocalStorageKeys {
  workspaceId = '@rialtic/workspaceId',
  selectedDateRange = '@rialtic/selectedDateRange',
}
export enum Role {
  Analyst = 'Analyst',
  Manager = 'Manager',
  SystemAdmin = 'SystemAdmin',
  UserAdmin = 'UserAdmin',
}

const hasConnectors = (
  workspaceId = '',
  connectorId = '',
  connectors: PaAPI.Get['/connector'] = [],
): boolean => !!(workspaceId && connectorId && connectors?.length)

export const useWorkspace = defineStore('workspace', {
  state: () => {
    const tenantConfig = useAppConfig().tenant

    return {
      connectorId: '',
      previousConnectorId: '',
      connectorRoles: {} as Record<string, boolean>,
      globalRoles: {} as Record<string, boolean>,
      user: null as User | null,
      userWorkspaces: {} as Record<string, PaAPI.Get['/workspace/{id}']>,
      userConnectors: [] as LocalConnector[],
      workspaceRoles: {} as Record<string, boolean>,
      workspaceId: tenantConfig.singleTenantWorkspaceId
        ? tenantConfig.singleTenantWorkspaceId
        : useLocalStorage(LocalStorageKeys.workspaceId, ''),
    }
  },

  getters: {
    activeConnector(state): LocalConnector | null {
      if (state.connectorId === ALL_LOB) return null
      return (
        this.connectors.find(({ id }) => id === state.connectorId.toString()) ||
        null
      )
    },

    activeWorkspace: (state): PaAPI.Get['/workspace/{id}'] | null => {
      const tenantConfig = useAppConfig().tenant

      const wId = tenantConfig.singleTenantWorkspaceId || state.workspaceId
      return wId ? state.userWorkspaces[wId] : null
    },

    canManageConnector(): boolean {
      return Boolean(this.activeConnector?.isManager)
    },

    inquiriesBaseUrl(): string {
      const baseUrl = this.activeWorkspace?.system_document
        ?.inquiries_base_url as string | undefined

      return baseUrl?.startsWith('http') ? baseUrl : ''
    },

    chartInfoLabel(): string {
      let info: string
      const { end: endDate, preset, start: startDate } = this.selectedDateRange

      // connector being in claims eval requires batch label
      if (this.activeConnector?.claim_eval) {
        info = `${this.activeConnector?.name} • ${this.activeConnector.batch_label}`
      } else {
        const dateRange = `${startDate} - ${endDate}`
        info = this.activeConnector?.name
          ? `${this.activeConnector?.name} • `
          : ''
        info +=
          preset !== datePresets.custom ? `${preset} (${dateRange})` : dateRange
      }

      return info
    },

    connectors: (state): LocalConnector[] =>
      state.workspaceId
        ? state.userConnectors
            .filter(({ workspace_id }) => workspace_id === state.workspaceId)
            .sort((a, b) => a.name.localeCompare(b.name))
        : [],

    hasAggregateConnectors(): boolean {
      return (
        this.connectors.filter(
          (conn) => !conn.system_document?.hide_from_aggregate_dashboard,
        ).length > 0
      )
    },

    hasConnectors(): boolean {
      return this.connectors.length > 0
    },

    hasMany: (state): boolean =>
      state.userWorkspaces && Object.keys(state.userWorkspaces).length > 1,

    isClaimEval(state): boolean {
      if (state.connectorId === ALL_LOB) {
        return this.connectors.every((conn) => conn.claim_eval)
      }
      return !!this.activeConnector?.claim_eval
    },

    isRialticUser: (): boolean => {
      const currentUser = useCurrentUser()

      return !!currentUser.value?.email.endsWith('@rialtic.io')
    },

    isRialticAdmin(): boolean {
      if (!this.userPermissions.length) {
        return false
      }

      return guard(
        'ANY',
        [
          PERMISSIONS.readWorkspacesAll,
          PERMISSIONS.readUsersAll,
          PERMISSIONS.readSystem,
        ],
        this.userPermissions,
      )
    },

    formattedDateWithPreset(): string {
      return this.isClaimEval
        ? (this.activeConnector?.batch_label as string) || 'All time'
        : `\
      ${this.selectedDateRange.preset} \
      (${formatDisplayDate(this.selectedDateRange.start, dateRangePatternAlt)} - \
      ${formatDisplayDate(this.selectedDateRange.end, dateRangePatternAlt)})\
      `
    },

    muxFilters(): MuxFilters {
      const appConfiguration = useAppConfiguration()

      const timeZone = 'America/New_York'

      const inputRange: [string, string] = this.isClaimEval
        ? [this.sinceInceptionDate, format(new Date(), 'yyyy-MM-dd')]
        : [this.selectedDateRange.start, this.selectedDateRange.end]

      const [start_date, end_date] = getDateRangeTimestamps(
        inputRange,
        timeZone,
        appConfiguration.feature_hiro_timestamps ? 'complete' : 'date',
      )

      return {
        connector_id: this.connectorId,
        start_date,
        end_date,
      }
    },

    muxIsConfigured(state): boolean {
      if (!state.workspaceId || !this.activeWorkspace?.mux_base_url) {
        return false
      }

      if (state.connectorId === ALL_LOB) {
        return true
      }

      return !!(state.connectorId && this.activeConnector)
    },

    selectedDateRange(): DatePickerRange {
      const { getUrlParams } = useUrlParamsState()

      const [start_date] = getUrlParams(DATE_START_KEY)
      const [end_date] = getUrlParams(DATE_END_KEY)

      if (start_date && end_date) {
        return {
          start: start_date,
          end: end_date,
        }
      }

      if (process.client) {
        try {
          const dateRange = JSON.parse(
            localStorage.getItem(LocalStorageKeys.selectedDateRange) as string,
          )
          if (dateRange) return dateRange
        } catch (error) {}
      }

      return getYearToDateRange()
    },

    sinceInceptionDate(): string {
      const minDate =
        this.connectorId === ALL_LOB
          ? min(
              this.connectors
                .filter(
                  (conn) =>
                    !conn.system_document?.hide_from_aggregate_dashboard,
                )
                .map((conn) =>
                  localDate(conn.inception_date ?? sinceInceptionDate),
                ),
            )
          : localDate(
              this.activeConnector?.inception_date ?? sinceInceptionDate,
            )

      return format(minDate, 'yyyy-MM-dd')
    },

    underMaintenance(): boolean {
      return this.activeWorkspace?.system_document?.under_maintenance === true
    },

    userPermissions(): string[] {
      const currentUser = useCurrentUser()

      return currentUser.value?.permissions || []
    },

    /** String of `${workspaceId}:${connectorId}:${start_date}:${end_date}:${claim_eval}` */
    watchKey(state): string {
      return `${state.workspaceId}:${state.connectorId}:${this.muxFilters.start_date}:${this.muxFilters.end_date}:${this.activeConnector?.claim_eval}`
    },
  },

  actions: {
    async fetchConnectorData(connectorId: string) {
      const connectorAnalytics = useConnectorAnalytics()
      const appConfig = useAppConfiguration()

      await this.waitForReady()

      const connector = this.getConnector(connectorId)

      if (!connector?.workspace_id) {
        return
      }

      const refreshEnabled =
        !connector.claim_eval ||
        !connector.system_document?.prevent_realtime_sync

      if (refreshEnabled && appConfig.feature_periodic_refresh) {
        connectorAnalytics.refreshConnectorAnalytics(
          connector.workspace_id,
          connector.id,
        )
      }

      connectorAnalytics.getAnalyticsAggregate(
        connector.workspace_id,
        connector.id,
      )
    },

    async findAndSetConnector(
      connectorId?: string | null,
      connectorOptions: ConnectorOptions = {},
    ) {
      if (connectorId && connectorOptions.force) {
        this.$patch({
          connectorId,
          workspaceId: '',
        })

        if (process.client) {
          localStorage.removeItem(LocalStorageKeys.workspaceId)

          await nextTick()
        }
      }
      const connId = connectorId || this.connectorId

      const tenantConfig = useAppConfig().tenant
      const connector = this.userConnectors
        .filter((conn) => {
          if (tenantConfig.singleTenantWorkspaceId) {
            return conn.workspace_id === tenantConfig.singleTenantWorkspaceId
          }
          return true
        })
        .find(({ id }) => id === connId)

      if (connector?.workspace_id) {
        this.setActiveWorkspace(connector.workspace_id)
        this.setActiveConnector(connector.id, connectorOptions)
        return connector
      }

      await this.getUser(connectorId || undefined)

      if (!this.userConnectors.length) {
        this.setActiveConnector('', connectorOptions)
        return null
      }

      const userConnector = this.userConnectors.find(({ id }) => id === connId)

      const setActiveConnectorId =
        userConnector?.id || this.connectors[0]?.id || ''

      this.setActiveConnector(setActiveConnectorId, connectorOptions)

      return this.activeConnector
    },

    getConnector(connectorId: string): LocalConnector | undefined {
      return this.userConnectors.find(({ id }) => connectorId === id)
    },

    async getUser(connectorId?: string) {
      const { $auth, $auth0, $datadog } = useNuxtApp()
      const { isAuthenticated, getAccessTokenSilently } = $auth0()

      if (!$auth.refreshToken && !isAuthenticated.value) {
        this.$reset()
        return null
      }

      try {
        const token = $auth.refreshToken
          ? await $auth.checkAndRefresh()
          : await getAccessTokenSilently()
        const client = useRpcClient(token)

        const res = await client.auth.user.$get()

        if (!res.ok) {
          const errorData = await res.json()
          $datadog.addError(new Error(`${res.status}: ${res.statusText}`))
          throw createError({
            statusCode: res.status,
            message: errorData.error,
          })
        }

        const { data } = await res.json()

        if (!data) {
          throw new Error('No user data returned')
        }

        this.$patch({
          globalRoles: data.globalRoles,
          connectorRoles: data.connectorRoles,
          workspaceRoles: data.workspaceRoles,
          userConnectors: data.connectors as any,
          userWorkspaces: data.workspaces,
          user: {
            id: data.id,
            email: data.email,
            first_name: data.first_name || '',
            last_name: data.last_name || '',
          },
        })

        if (connectorId) {
          this.setActiveConnector(connectorId)

          const connector = this.getConnector(connectorId)
          if (connector?.workspace_id)
            this.setActiveWorkspace(connector.workspace_id)
          return data
        }
        const wId =
          this.workspaceId || localStorage.getItem(LocalStorageKeys.workspaceId)

        if (wId && data.workspaces[wId]) {
          this.setActiveWorkspace(wId)
        } else {
          this.setActiveWorkspace(Object.keys(data.workspaces)[0])
        }

        return data
      } catch (error) {
        $datadog.addError(error)
        return null
      }
    },

    setActiveConnector(id: string, options: ConnectorOptions = {}): string {
      const { deleteUrlParam, setUrlParams } = useUrlParamsState()
      if (!id) {
        this.connectorId = ''
        deleteUrlParam('lob')
        return id
      }
      if (id === this.connectorId) return id

      if (this.connectorId && this.connectorId !== ALL_LOB) {
        this.previousConnectorId = this.connectorId
      }

      const { $datadog } = useNuxtApp()
      $datadog.setUserProperty('connector_id', id)
      $datadog.setGlobalContextProperty('connector_id', id)
      this.connectorId = id

      if (id === ALL_LOB) {
        return id
      }

      this.syncPaApi()
      this.fetchConnectorData(id)

      if (options.middleware) {
        return id
      }

      setUrlParams('lob', id)

      return id
    },

    async setActiveWorkspace(id: string): Promise<string> {
      const { $datadog } = useNuxtApp()

      const tenantConfig = useAppConfig().tenant
      if (tenantConfig.singleTenantWorkspaceId) {
        if (this.workspaceId !== tenantConfig.singleTenantWorkspaceId) {
          this.workspaceId = tenantConfig.singleTenantWorkspaceId
        }
        $datadog.setUserProperty(
          'workspace_id',
          tenantConfig.singleTenantWorkspaceId,
        )
        $datadog.setGlobalContextProperty(
          'workspace_id',
          tenantConfig.singleTenantWorkspaceId,
        )
        return tenantConfig.singleTenantWorkspaceId
      }
      if (id && id === this.workspaceId) return id

      if (id) {
        localStorage.setItem(LocalStorageKeys.workspaceId, id)
      }

      $datadog.setUserProperty('workspace_id', id)
      $datadog.setGlobalContextProperty('workspace_id', id)

      this.workspaceId = id

      return id
    },

    setSelectedDateRange(dateRange: Api.DateRange) {
      localStorage.setItem(
        LocalStorageKeys.selectedDateRange,
        JSON.stringify(dateRange),
      )

      const { patchUrlParams } = useUrlParamsState()
      patchUrlParams({
        [DATE_START_KEY]: dateRange.start,
        [DATE_END_KEY]: dateRange.end,
      })

      this.syncPaApi()
    },

    async switchWorkspace(workspaceId: string) {
      const tenantConfig = useAppConfig().tenant

      if (tenantConfig.singleTenantWorkspaceId) {
        return tenantConfig.singleTenantWorkspaceId
      }

      const { deleteUrlParam } = useUrlParamsState()

      deleteUrlParam('lob')
      this.setActiveConnector('')
      this.setActiveWorkspace(workspaceId)

      if (this.connectors.length) {
        this.setActiveConnector(this.connectors[0].id)
      }

      return workspaceId
    },

    async syncPaApi({ flush } = { flush: false }) {
      if (!this.connectorId || this.connectorId === ALL_LOB) {
        return
      }
      const { $auth, $auth0, $datadog } = useNuxtApp()
      const { getAccessTokenSilently, isAuthenticated } = $auth0()

      try {
        const token = $auth.refreshToken
          ? await $auth.checkAndRefresh()
          : await getAccessTokenSilently()

        const client = useRpcClient(token)

        if (!$auth.refreshToken && !isAuthenticated.value) {
          this.$reset()
          return null
        }

        await client.event.connector[':connectorId'].sync.$get({
          param: {
            connectorId: this.connectorId,
          },
          query: {
            start_date: this.muxFilters.start_date,
            end_date: this.muxFilters.end_date,
            ...(flush && { flush: 'true' }),
          },
        })
      } catch (error) {
        $datadog.addError(error)
      }
    },

    waitForReady(timeout = 3000): Promise<boolean> {
      return new Promise((resolve, reject) => {
        if (
          hasConnectors(this.workspaceId, this.connectorId, this.connectors)
        ) {
          return resolve(true)
        }

        const activeTimeout = setTimeout(async () => {
          if (
            hasConnectors(this.workspaceId, this.connectorId, this.connectors)
          ) {
            return resolve(true)
          }
          try {
            await this.getUser()
            return resolve(true)
          } catch (error) {
            return reject(error)
          }
        }, timeout)

        const sub = this.$subscribe((_e, state) => {
          if (
            hasConnectors(state.workspaceId, state.connectorId, this.connectors)
          ) {
            clearTimeout(activeTimeout)
            sub()
            return resolve(true)
          }
        })
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useWorkspace, import.meta.hot))
