import { createSharedComposable, useUrlSearchParams as useVueUseUrlSearchParams, type UseUrlSearchParamsOptions } from '@vueuse/core'
import { useRoute } from '#imports'

export const sharedUrlSearchParams = createSharedComposable(useVueUseUrlSearchParams)

const paramsInstances = new Map<string, ReturnType<typeof useVueUseUrlSearchParams>>()

export const useRouteQuery = createSharedComposable(<T extends Record<string, any> = Record<string, any>>(key?: string, options?: UseUrlSearchParamsOptions<T> ) => {
  if (import.meta.server) {
    return useRoute().query as T
  }

  const finalKey = key ?? window.location.pathname
  if (!paramsInstances.has(finalKey)) {
    paramsInstances.set(finalKey, sharedUrlSearchParams('history', options))
  }
  const query = paramsInstances.get(finalKey)! as T

  if (window.location.pathname.startsWith('/auth/callback')) {
    query.code = undefined
    query.state = undefined
  }
  Object.assign(query, getUrlQuery())
  return query
})

function getUrlQuery() {
  if (window.location.pathname.startsWith('/auth')) {
    return {}
  }
  return Object.fromEntries(new URLSearchParams(window.location.search))
}
